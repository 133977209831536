import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Work6 from "../../assets/work6.jpg";
import Work7 from "../../assets/work7.jpg";
import Work8 from "../../assets/work8.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work7,
    title: "Microcemento en piso",
    category: "Microcemento",
  },
  {
    id: 2,
    image: Work8,
    title: "Microcemento pulido en piso",
    category: "Microcemento",
  },
  {
    id: 3,
    image: Work3,
    title: "Microhormigon para estructura metalica",
    category: "Microhormigon",
  },
  {
    id: 4,
    image: Work4,
    title: "Techo en microhormigon ",
    category: "Microhormigon",
  },
  {
    id: 5,
    image: Work5,
    title: "Mueble revestido con microcemento",
    category: "Microcemento",
  },
  {
    id: 6,
    image: Work2,
    title: "Microcemento fino pulido en pared",
    category: "Microcemento",
  },
  {
    id: 7,
    image: Work1,
    title: "Pared de microhormigon",
    category: "Microhormigon",
  },
  {
    id: 8,
    image: Work6,
    title: "Microhormigon acabado en listones",
    category: "Microhormigon",
  },
];

// projects
export const projectsNav = [
  {
    name: "Todo",
  },
  {
    name: "Microhormigon",
  },
  {
    name: "Microcemento",
  },
];
