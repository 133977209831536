import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Foto Concret EIRL</h1>
        <br/>
        <h3 className="footer__subtitle">RUC 20610079572</h3>

        <ul className="footer__list">
          <li>
            <a href="#products" className="footer__link">
              Productos
            </a>
          </li>

          <li>
            <a href="#portfolio" className="footer__link">
              Proyectos
            </a>
          </li>

          <li>
            <a href="#testimonials" className="footer__link">
              Testimonios
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.facebook.com/people/Microcemento-Durocreto/100051054500723/"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </a>

          <a
            href="https://api.whatsapp.com/send?phone=967572057&text=Hola,%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-whatsapp"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
