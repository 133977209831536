import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Eduardo Galimbert",
    description:
      "Microcemento TCON (Uruguay)",
  },
  {
    id: 2,
    image: Image2,
    title: "Carlos Azar Saba",
    description:
      "Sociedad constructora polonesa LTDA (Chile)",
  },
  {
    id: 3,
    image: Image3,
    title: "Robert Bak",
    description:
      "Optimum systemy dekoracji wnetrz (Polonia)",
  },
];
