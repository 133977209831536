import React from "react";
import "./about.css";
import AboutImg from "../../assets/about.jpg";
import Info from "./Info";

const About = () => {
  return (
    <section className="about section" id="products">
      <h2 className="section__title">Productos en Venta</h2>
      <span className="section__subtitle"></span>

      <div className="about__container container grid">
        <img src={AboutImg} alt="" className="about__img" />

        <div className="about__data">
          <Info />

          <p className="about__description">
            Venta de material y formulación, técnicas de aplicación e implemetación de taller de producción, algunos productos son:
            Primer, liquído adherente, base niveladora y refuerzo, <strong>microcemento fino, microhormigón fino y grueso</strong>, entre otros.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
