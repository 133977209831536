import React from "react";

import "./contact.css";

const Contact = () => {

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Contactame</h2>
      <span className="section__subtitle"></span>

      <div className="contact__container container grid">
        <div className="contact__content">

          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card-icon"></i>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">microcemento_durocreto@hotmail.es</span>

              <a
                href="mailto:microcemento_durocreto@hotmail.es"
                className="contact__button"
              >
                Escríbeme{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-whatsapp contact__card-icon"></i>

              <h3 className="contact__card-title">Whatsapp</h3>
              <span className="contact__card-data">967-572-057</span>

              <a
                href="https://api.whatsapp.com/send?phone=967572057&text=Hola, deseo más información sobre sus servicios"
                className="contact__button" target="_blank" rel="noreferrer" 
              >
                Escríbeme{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-messenger contact__card-icon"></i>

              <h3 className="contact__card-title">Facebook</h3>
              <span className="contact__card-data">Microcemento durocreto</span>

              <a href="https://www.facebook.com/people/Microcemento-Durocreto/100051054500723/" className="contact__button"
              target="_blank" rel="noreferrer">
              Escríbeme{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>

        
      </div>
    </section>
  );
};

export default Contact;
